@import "variables/common/mobile-newsletter";
@import "variables/common/forms";
@import "variables/layout/general-rules";
@import "variables/layout/footer";
@import "../../base/styles/base";

footer .whychoose .tableContainer .box I {
	@include media-breakpoint-up(lg) {
		top: 0 !important;
	}
}